import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/system';
import {
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Snackbar,
  Tooltip,
  Chip,
} from '@mui/material';
import {
  DeleteForeverSharp,
  Close as CloseIcon,
  OpenInNew,
} from '@mui/icons-material';
import { PAGINATION_OPTIONS } from '../constants';
import faqsApi from '../api/faqs.api';
import JSONViewer from './JSONViewer';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h5 style={{ textAlign: 'left', padding: '5px' }}>FAQ Mapping</h5>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const CustomDialogTitle = styled(({ children, onClose, ...other }) => (
  <DialogTitle {...other}>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          className={CustomDialogTitle.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  </DialogTitle>
))(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#343a40',
    color: 'white',
  },
  closeButton: {
    position: 'relative',
    top: '-50%',
    color: theme.palette.grey[500],
  },
}));

function FaqsMappingList({ filter, viewHeight = '30vh' }) {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: '',
  });
  const handleDelete = (row) => {
    setSelectedRow(row);
    setOpenConfirmDelete(true);
  };

  const goToOfferDetails = (detailfaqId) => {
    window.open(`/faqs-mapping/${detailfaqId}`, '_blank');
  };

  const handleCellClick = (cellParams) => {
    if (cellParams.field === 'details') {
      goToOfferDetails(cellParams.row._id);
    }
  };

  const columns = [
    {
      headerName: 'Created',
      field: 'createdAt',
      headerClassName: 'custom-dark-theme--header',
      width: 220,
      renderCell: (params) => {
        return (
          <div>
            {/* Display Formatted Date */}
            <JSONViewer title="Mapping List" jsonData={params.row} />
            {new Date(params.row.createdAt).toLocaleDateString()}
          </div>
        );
      },
    },
    {
      headerName: 'Mapping Type',
      render: (e) => e.mappingType,
      field: 'mappingType',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Mapping Id',
      render: (e) => e.mappingId,
      field: 'mappingId',
      headerClassName: 'custom-dark-theme--header',
      width: 200,
    },
    {
      headerName: 'Status',
      field: 'isActive',
      headerClassName: 'custom-dark-theme--header',
      width: 150,
      renderCell: (params) => (
        <Chip
          label={params.row.isActive ? 'Active' : 'Inactive'}
          sx={{
            backgroundColor: params.row.isActive ? 'green' : 'red',
            color: 'white',
            fontWeight: 'bold',
          }}
        />
      ),
    },
    {
      headerName: 'Details',
      renderCell: () => (
        <IconButton>
          <OpenInNew />
        </IconButton>
      ),
      field: 'details',
      width: 70,
      disableColumnMenu: true,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <Tooltip title="Delete" arrow>
            <IconButton
              onClick={() => handleDelete(params.row)}
              color="secondary"
            >
              <DeleteForeverSharp />
            </IconButton>
          </Tooltip>
        </div>
      ),
      disableColumnMenu: true,
    },
  ];
  const [dataSource, setDataSource] = useState([]);
  const [pageSize, setPageSize] = React.useState(PAGINATION_OPTIONS[2]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchReport = async () => {
    const params = {
      skip: page * pageSize,
      limit: pageSize,
      appRegion: process.env.REACT_APP_DEFAULT_REGION,
      filter: JSON.stringify(filter),
    };
    setIsLoading(true);
    const response = await faqsApi.getFaqsMappingList(dispatch, params);
    return response;
  };

  function updateDataSource(data) {
    const startIndex =
      dataSource && dataSource.length
        ? dataSource[dataSource.length - 1]._gridId + 1
        : 0;
    const _updatedDataSource = dataSource.concat(
      data.map((item, index) => {
        return { ...item, _gridId: index + startIndex };
      })
    );
    setDataSource(_updatedDataSource);
    return _updatedDataSource;
  }

  useEffect(() => {
    const loadData = async () => {
      const reports = await fetchReport();
      if (reports && reports.data) {
        const totalRecords = reports.totalCount;
        const currentPageRecords = reports.data;

        // Calculate if we're on the last page
        const isLastPage = page === Math.ceil(totalRecords / pageSize) - 1;

        // If on the last page, only show the remaining records
        const recordsToDisplay = isLastPage
          ? currentPageRecords.slice(0, totalRecords % pageSize)
          : currentPageRecords;

        updateDataSource(recordsToDisplay);
        setRowCount(totalRecords);
      }
      setIsLoading(false);
    };
    loadData();
  }, [page, pageSize, filter]);

  const handlePageChange = (params) => {
    setPage(params.page);
    setPageSize(params.pageSize);
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const confirmDelete = async () => {
    try {
      if (selectedRow) {
        await faqsApi.deleteFaqsTags(dispatch, selectedRow._id);
        setOpenConfirmDelete(false);
        // Update dataSource after deletion
        setDataSource((prev) =>
          prev.filter((item) => item._id !== selectedRow._id)
        );
        setSnackbar({
          open: true,
          message: 'Faq Tag deleted successfully!',
          severity: 'success',
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete the faq.',
        severity: 'error',
      });
    }
  };
  return (
    <div className="container">
      <div
        style={{
          height: viewHeight,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            density="compact"
            paginationModel={{ page, pageSize }}
            pagination
            pageSizeOptions={PAGINATION_OPTIONS}
            rowCount={rowCount}
            getRowId={(row) => row._gridId}
            rows={dataSource}
            columns={columns}
            onPaginationModelChange={handlePageChange}
            onCellClick={handleCellClick}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        )}
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      >
        <DialogTitle>Are you sure you want to delete this Faq Tag?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor:
              snackbar.severity === 'error' ? '#ff4c4c' : 'green',
            color: snackbar.severity === 'error' ? '#fefefe' : 'white',
            textAlign: 'center',
          },
        }}
      />
    </div>
  );
}

export default FaqsMappingList;
