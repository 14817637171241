import React from 'react';
import Select from 'react-select';
import {
  Card,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Row, Col } from 'react-bootstrap';

function MappingTagsSection({
  selectedTags,
  availableTags,
  onTagChange,
  onSaveTags,
  isEditing,
  setIsEditing,
}) {
  const handleTagChange = (selectedOptions) => {
    const updatedTags = selectedOptions.map((option) => ({
      _id: option.value,
      label: option.label,
    }));
    console.log('Updated Tags:', selectedOptions);
    onTagChange(updatedTags);
  };

  const handleSave = () => {
    onSaveTags(selectedTags);
    setIsEditing(false);
  };

  return (
    <Card variant="outlined" className="p-3">
      <Row>
        <Col>
          <Typography
            variant="h5"
            className="mb-4"
            style={{ textAlign: 'left' }}
          >
            Tags
          </Typography>
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <IconButton onClick={() => setIsEditing(true)} size="small">
            <Edit />
          </IconButton>
        </Col>
      </Row>
      <Row className="t-4" style={{ minHeight: '50px' }}>
        <Col>
          <p className="card-content-align">
            {selectedTags.length > 0 ? (
              selectedTags.map((tag) => (
                <Chip
                  key={tag._id}
                  label={tag.label}
                  style={{ margin: 4, backgroundColor: 'blue', color: 'white' }}
                />
              ))
            ) : (
              <span>No tags selected</span>
            )}
          </p>
        </Col>
      </Row>

      {/* Button to open the multi-select in popup */}
      {isEditing && (
        <Dialog
          open={isEditing}
          onClose={() => setIsEditing(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Select Tags</DialogTitle>
          <DialogContent sx={{ overflowY: 'auto', paddingBottom: 0 }}>
            <Select
              isMulti
              value={selectedTags.map((tag) => ({
                value: tag._id,
                label: tag.label,
              }))}
              options={availableTags.map((tag) => ({
                value: tag._id,
                label: tag.label,
              }))}
              onChange={handleTagChange}
              placeholder="Select tags..."
              isClearable
              isSearchable
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({ ...base, zIndex: 9999 }), // Additional safety for zIndex
                multiValue: (base) => ({
                  ...base,
                  margin: '4px', // Adds spacing between selected tags
                  padding: '4px 8px', // Adds padding inside each tag
                }),
              }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: '16px 24px', gap: '8px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              sx={{ padding: '8px 16px', marginRight: '8px' }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => setIsEditing(false)}
              sx={{ padding: '8px 16px' }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
}

export default MappingTagsSection;
