export const DEFAULT_TIMEOUT = 5000;
export const PAGINATION_OPTIONS = [10, 25, 50, 100, 250, 500, 1000];
export const NA = 'NOT AVAILABLE';
export const SMS_ERROR_CODE_MAPPINGS = {
  10002: 'Trial account does not support this feature',
  11200: 'HTTP retrieval failure',
  11751: 'MMS -> Media exceeds mobile operator size limit',
  12100: 'Document parse failure',
  12300: 'Invalid Content-Type',
  13221: 'Dial->Number: Invalid method value',
  15002: 'Call Progress: Queue Timeout',
  21210: "'From' phone number not verified",
  21219: "'To' phone number not verified",
  21601: 'Phone number is not a valid SMS-capable inbound phone number',
  21602: 'Message body is required',
  21603: "The source 'From' phone number is required to send an SMS",
  21604: "The destination 'To' phone number is required to send an SMS",
  21610: 'Attempt to send to unsubscribed recipient',
  21611:
    "This 'From' number has exceeded the maximum number of queued messages",
  21617: 'The concatenated message body exceeds the 1600 character limit',
  21620: 'Invalid media URL(s)',
  21623: 'Number of media files exceeds allowed limit',
  30002: 'Account suspended',
  30003: 'Unreachable destination handset',
  30004: 'Message blocked or opted out',
  30005: 'Unknown destination handset',
  30006: 'Landline or unreachable carrier',
  30007: 'Message marked as spam',
  30008: 'Unknown error',
  30010: 'Message price exceeds max price',
};

export const AppConfigMapping = {
  SYSTEM_CONFIG: 'SYSTEM CONFIG',
  CONFIG_HISTORY_SIZE: 'CONFIG HISTORY SIZE',
  MAP_CONFIG: 'MAP CONFIG',
  MINIMUM_MILES_RADIUS_FOR_SEARCH: 'MINIMUM MILES RADIUS FOR SEARCH',
  SHARE_CONFIG: 'SHARE CONFIG',
  SHARE_GENERIC_LIMIT: 'SHARE GENERIC LIMIT',
  SHARE_CAR_USERS_LIMIT: 'SHARE CAR USERS LIMIT',
  NOTIFICATION_CONFIG: 'NOTIFICATION CONFIG',
  CATEGORIES_TOGGLE_ENABLED: 'CATEGORIES TOGGLE ENABLED',
  CAR_MAINTENANCE_REMINDERS: 'CAR MAINTENANCE REMINDERS',
  GLOVEBOX: 'GLOVEBOX',
  SALES_AND_PROMOTIONS: 'SALES AND PROMOTIONS',
  CLAIM_STATUS_UPDATES: 'CLAIM STATUS UPDATES',
  SHARE_AND_TRANSFER: 'SHARE AND TRANSFER',
  UPDATE_CAR_PROFILE_REMINDERS: 'UPDATE CAR PROFILE REMINDERS',
  DEALER_PROMOTIONS_THROTTLING_CONFIG: 'DEALER PROMOTIONS THROTTLING CONFIG',
  DEALER_THROTTLE_WEEKS: 'DEALER THROTTLE WEEKS',
  PROMOTIONS_THROTTLE_DAYS: 'PROMOTIONS THROTTLE DAYS',
  EXPIRING_PRODUCT_CONFIG: 'EXPIRING PRODUCT CONFIG',
  DAYS_BEFORE_NOTIFY: 'DAYS BEFORE NOTIFY',
  MILES_BEFORE_NOTIFY: 'MILES BEFORE NOTIFY',
  EXPIRING_FACTORY_WARRANTY_CONFIG: 'EXPIRINGFACTORY WARRANTY CONFIG',
  BUMPER_TO_BUMPER_MILES_BEFORE_NOTIFY: 'BUMPER TO BUMPER MILES BEFORE NOTIFY',
  BUMPER_TO_BUMPER_DAYS_BEFORE_NOTIFY: 'BUMPER TO BUMPER DAYS BEFORE NOTIFY',
  POWER_TRAIN_MILES_BEFORE_NOTIFY: 'POWER TRAIN MILES BEFORE NOTIFY',
  POWER_TRAIN_DAYS_BEFORE_NOTIFY: 'POWER TRAIN DAYS BEFORE NOTIFY',
  CAR_MAINTENANCE_CONFIG: 'CAR MAINTENANCE CONFIG',
  UPCOMING_MAINTENANCE_MILES_BEFORE_NOTIFY:
    'UPCOMING MAINTENANCE MILES BEFORE NOTIFY',
  UPCOMING_MAINTENANCE_DAYS_BEFORE_NOTIFY:
    'UPCOMING MAINTENANCE DAYS BEFORE NOTIFY',
  JUST_PASSED_MAINTENANCE_MILES_AFTER_NOTIFY:
    'JUST PASSED MAINTENANCE MILES AFTER NOTIFY',
  JUST_PASSED_MAINTENANCE_DAYS_AFTER_NOTIFY:
    'JUST PASSED MAINTENANCE DAYS AFTER NOTIFY',
  CAR_MILEAGE_CONFIG: 'CAR MILEAGE CONFIG',
  MILEAGE_REMINDER_DAYS_AFTER_CAR_CREATED:
    'MILEAGE REMINDER DAYS AFTER CAR CREATED',
  MILEAGE_REMINDER_DAYS_AFTER_LAST_UPDATE:
    'MILEAGE REMINDER DAYS AFTER LAST UPDATE',
  CAR_SALES_DATE_CONFIG: 'CAR SALES DATE CONFIG',
  FIRST_REMINDER_DAYS_AFTER: 'FIRST REMINDER DAYS AFTER',
  SECOND_REMINDER_DAYS_AFTER: 'SECOND REMINDER DAYS AFTER',
  INVITATION_CONFIG: 'INVITATION CONFIG',
  ENABLE_INVITES_FOR_DEALER_CSV: 'ENABLE INVITES FOR DEALER CSV',
  ENABLE_INVITES_FOR_DEALER_JSON: 'ENABLE INVITES FOR DEALER JSON',
  ENABLE_INVITES_FOR_FORMS_SERVICE: 'ENABLE INVITES FOR FORMS SERVICE',
  ENABLE_INVITES_FOR_GLOW_JSON: 'ENABLE INVITES FOR GLOW JSON',
  ENABLE_INVITES_FOR_OPERATIONAL_ACQUISTION:
    'ENABLE INVITES FOR OPERATIONAL ACQUISTION',
  INVITATION_CONTRACTS_EXPIRY: 'INVITATION CONTRACTS EXPIRY',
  INVITATION_EXPIRY: 'INVITATION EXPIRY',
  INVITATION_LOG_EXPIRY: 'INVITATION LOG EXPIRY',
  INVITATION_SMS_SEND_DELAY: 'INVITATION SMS SEND DELAY',
  REPORT_CONFIG: 'REPORT CONFIG',
  REPORT_DEFAULT_PAGE_SIZE: 'REPORT DEFAULT PAGE SIZE',
  REPORT_FILTER_EMAIL_PATTERNS: 'REPORT FILTER EMAIL PATTERNS',
  REPORT_FILTER_PHONE_NUMBER_PATTERNS: 'REPORT FILTER PHONE NUMBER PATTERNS',
  REPORT_SHOW_USER_EMAIL: 'REPORT SHOW USER EMAIL',
  REPORT_SHOW_USER_PHONE_NUMBER: 'REPORT SHOW USER PHONE NUMBER',
  REPORT_USER_EMAIL_FILTER_PATTERNS: 'REPORT USER EMAIL FILTER PATTERNS',
  REPORT_USER_PHONE_NUMBER_FILTER_PATTERNS:
    'REPORT USER PHONE NUMBER FILTER PATTERNS',
};

export const CSV_FILE_ONLY = 'Please Upload a csv file only.';
export const INCORRECT_CSV = 'The csv file uploaded is not valid';

export const US_REGION_LIST = ['US', 'MX'];
export const UK_REGION_LIST = ['UK', 'DE', 'FR', 'IT', 'ES'];

export const ADMIN_ROLES_LIST = [
  'VCP AppRole_SuperAdmin',
  'VCP AppRole_BusinessAdmin',
  'VCP AppRole_TechSupport',
  'VCP AppRole_ClientService',
  'VCP AppRole_CustomerService',
  'VCP AppRole_DealerAdmin',
  'PGA AppRole_Admin',
];

export const REGION_CONFIG = {
  dev: {
    REGION_OPTIONS: [
      {
        REGION_NAME: 'VCP 2.0 US Dev',
        REGION_VALUE: 'US',
        REGION_ENV_URL: 'https://api.devpocketgeekauto.com',
      },
      {
        REGION_NAME: 'VCP 2.0 UK Dev',
        REGION_VALUE: 'UK',
        REGION_ENV_URL: 'https://dev-uk-api.devvehiclecareplan.com',
      },
    ],
  },
  test: {
    REGION_OPTIONS: [
      {
        REGION_NAME: 'VCP 2.0 US Test',
        REGION_VALUE: 'US',
        REGION_ENV_URL: 'https://test-api.devpocketgeekauto.com',
      },
      {
        REGION_NAME: 'VCP 2.0 UK Test',
        REGION_VALUE: 'UK',
        REGION_ENV_URL: 'https://test-uk-api.devvehiclecareplan.com',
      },
    ],
  },
  prod: {
    REGION_OPTIONS: [
      {
        REGION_NAME: 'VCP 2.0 US Production',
        REGION_VALUE: 'US',
        REGION_ENV_URL:
          'https://wt4fb7ont0.execute-api.us-east-1.amazonaws.com/assurantprod',
      },
      {
        REGION_NAME: 'VCP 2.0 UK Production',
        REGION_VALUE: 'UK',
        REGION_ENV_URL: 'https://api.yourvehiclecare.co.uk',
      },
    ],
  },
};

export const PRODUCT_TYPES = {
  BUN: 'BUN',
  WAR: 'WAR',
  LW: 'LW',
  PDR: 'PDR',
  LUX: 'LUX',
  MC: 'MC',
  PPM: 'PPM',
  WP: 'WP',
  TWP: 'TWP',
  GAP: 'GAP',
  KEY: 'KEY',
  WNS: 'WNS',
  WS: 'WS',
};
